/* eslint-disable react/no-danger */
import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { isDefined } from "repoV2/utils/common/dataTypes/common";
import { WHITELABEL_FEATURE_KEY } from "repoV2/features/CreatorPages/constants/CreatorPages.constants";
import { checkIsObjectEmpty } from "repoV2/utils/common/dataTypes/object";
import { useFeatureAccessibility } from "repoV2/features/CreatorDetails/modules/FeatureAccessibility/utils/useFeatureAccessibility";
import { PoweredByExly } from "repoV2/features/CreatorPages/modules/PoweredByExly";
import { checkIsArrayEmpty } from "repoV2/utils/common/dataTypes/array";
import styles from "./footer.module.scss";
import { useFooterSection } from "./utils/useFooterSection";
import { FooterItem } from "./modules/FooterItem/FooterItem";
import { PrivacyPolicy } from "../PrivacyPolicy";
import { TermsOfUse } from "../TermsOfUse";
import { Copyright } from "../Copyright";
import { ICreatorPagesFooter } from "./FooterSection.interfaces";

export const FooterSection = ({
    tnCLink,
    handleClick,
    customFooterContent, // To insert custom text in the middle of a footer
    template,
    overrideFooterDefaultText = {}, // To override the text shown on either side of the footer
}: ICreatorPagesFooter.IFooterSectionProps): JSX.Element => {
    const { customFooter } = useFooterSection();
    const isWhitelabelActive = useFeatureAccessibility({
        featureKey: WHITELABEL_FEATURE_KEY,
    });

    if (customFooter?.hide_footer === true) {
        return <></>;
    }

    if (
        !checkIsObjectEmpty(customFooter) &&
        (!checkIsArrayEmpty(customFooter.left_section) ||
            !checkIsArrayEmpty(customFooter.right_section))
    ) {
        return (
            <div className={classNames(styles.root, styles?.[template])}>
                <div className={styles.custom_footer_container}>
                    <div className="row">
                        <div className={` col-6`}>
                            {!checkIsArrayEmpty(customFooter.left_section) && (
                                <>
                                    {customFooter.left_section?.map(item => (
                                        <FooterItem item={item} />
                                    ))}
                                </>
                            )}
                        </div>
                        <div className={` col-6`}>
                            {!checkIsArrayEmpty(customFooter.right_section) && (
                                <>
                                    {customFooter.right_section?.map(item => (
                                        <div className={` text-right`}>
                                            <FooterItem item={item} />
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // old legacy code below

    // since we have to hide PoweredByExly & Copyright when whitelabel we have to show PrivacyPolicy & TermsOfUse in single line
    const bottomLeft = isWhitelabelActive ? null : (
        <TermsOfUse handleClick={handleClick} tnCLink={tnCLink} />
    );

    const topRight = isWhitelabelActive ? (
        <div>
            <TermsOfUse handleClick={handleClick} tnCLink={tnCLink} />
        </div>
    ) : (
        <PoweredByExly />
    );

    const footerContent = {
        topLeft: isDefined(overrideFooterDefaultText?.top_left) ? (
            <div
                dangerouslySetInnerHTML={{
                    __html: overrideFooterDefaultText?.top_left,
                }}
            />
        ) : (
            <PrivacyPolicy handleClick={handleClick} />
        ),
        bottomLeft: isDefined(overrideFooterDefaultText?.bottom_left) ? (
            <div
                dangerouslySetInnerHTML={{
                    __html: overrideFooterDefaultText?.bottom_left,
                }}
            />
        ) : (
            bottomLeft
        ),
        topRight: isDefined(overrideFooterDefaultText?.top_right) ? (
            <div
                dangerouslySetInnerHTML={{
                    __html: overrideFooterDefaultText?.top_right,
                }}
            />
        ) : (
            topRight
        ),
        bottomRight: isDefined(overrideFooterDefaultText?.bottom_right) ? (
            <div
                dangerouslySetInnerHTML={{
                    __html: overrideFooterDefaultText?.bottom_right,
                }}
            />
        ) : (
            <Copyright />
        ),
    };

    return (
        <div className={classNames(styles.root, styles?.[template])}>
            <div className={styles.container}>
                <div className={styles.column}>
                    {footerContent.topLeft}
                    {footerContent.bottomLeft}
                </div>

                {customFooterContent ? (
                    <div
                        className={styles.customFooterText}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: customFooterContent,
                        }}
                    />
                ) : null}

                <div className={styles.column}>
                    {footerContent.topRight}
                    {footerContent.bottomRight}
                </div>
            </div>
        </div>
    );
};
